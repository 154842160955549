// src/App.js
import React from 'react';
import './App.css';
import Logo from './Logo';
import Tagline from './Tagline';
import LeadershipTeam from './LeadershipTeam';
import OurScience from './OurScience';
import Careers from './Careers';
import ContactUs from './ContactUs';
import Footer from './Footer';
import ScrollArrow from './ScrollArrow';

function App() {
  return (
    <div className="App">
      <div className="hero-section">
        <div className="hero-background"></div>
        <div className="hero-content">
          <Logo />
          <Tagline />
          <ScrollArrow />
        </div>
      </div>
      <div className="content-wrap">
        <OurScience />
        <LeadershipTeam />
        <Careers />
        <ContactUs />
      </div>
      <Footer />
    </div>
  );
}

export default App;