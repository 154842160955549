// src/ScrollArrow.js
import React from 'react';
import './ScrollArrow.css';

const ScrollArrow = () => {
  const scrollDown = () => {
    // Scroll to the Our Science section smoothly
    const scienceSection = document.querySelector('.our-science');
    if (scienceSection) {
      scienceSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="scroll-arrow" onClick={scrollDown}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/>
      </svg>
    </div>
  );
};

export default ScrollArrow;