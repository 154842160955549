// src/Careers.js
import React, { useState, useEffect, useRef } from 'react';
import './Careers.css';

const Careers = () => {
  const sectionRef = useRef(null);
  const [hasPositions, setHasPositions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentSection = sectionRef.current;
    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      if (currentSection) {
        observer.unobserve(currentSection);
      }
    };
  }, []);

  useEffect(() => {
    // Check if there are any open positions
    const checkPositions = async () => {
      try {
        // We'll use the BambooHR API endpoint for jobs
        // This is a GET request to fetch job listings
        const response = await fetch(
          'https://forthtx.bamboohr.com/jobs/embed2.php',
          { method: 'GET' }
        );
        
        const html = await response.text();
        
        // If there are no positions, the response will contain specific text
        // We check for that text to determine if there are any positions
        setHasPositions(!html.includes('We currently have no open positions'));
        setIsLoading(false);
      } catch (error) {
        console.error('Error checking positions:', error);
        setHasPositions(false);
        setIsLoading(false);
      }
    };
    
    checkPositions();
  }, []);

  useEffect(() => {
    // Only load BambooHR script if there are positions
    if (hasPositions === true) {
      const script = document.createElement('script');
      script.src = 'https://forthtx.bamboohr.com/js/embed.js';
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;
      script.id = 'bamboohr-script';

      document.body.appendChild(script);

      return () => {
        if (document.getElementById('bamboohr-script')) {
          document.body.removeChild(script);
        }
      };
    }
  }, [hasPositions]);

  return (
    <div className="careers" ref={sectionRef}>
      <h2>Careers</h2>
      
      {isLoading ? (
        <div className="careers-loading">
          <p>Loading opportunities...</p>
        </div>
      ) : hasPositions ? (
        // Show BambooHR embed if positions exist
        <div
          id="BambooHR"
          data-domain="forthtx.bamboohr.com"
          data-version="1.0.0"
          data-departmentId=""
        ></div>
      ) : (
        // Show our custom message if no positions exist
        <div className="custom-careers-message">
          <h3>Open Positions</h3>
          <div className="careers-content">
            <p className="no-positions">We currently have no open positions.</p>
            <p className="check-back">Please check back soon as we will be looking for great people to join our team in the near future.</p>
          </div>
          <div className="careers-footer">
            <p className="powered-by">Powered by <a href="https://www.bamboohr.com" target="_blank" rel="noopener noreferrer">BambooHR</a></p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Careers;