// src/OurScience.js
import React, { useEffect, useRef } from 'react';
import './OurScience.css';

const OurScience = () => {
  const sectionRef = useRef(null);
  const titleRef = useRef(null);
  const introRef = useRef(null);
  
  // Create refs array using useMemo to keep it stable across renders
  const rowRefs = useRef([]);
  
  // Initialize the refs array once
  if (rowRefs.current.length !== 3) {
    rowRefs.current = Array(3).fill().map(() => React.createRef());
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.15 }
    );

    // Observe the main section
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    // Observe the title
    if (titleRef.current) {
      observer.observe(titleRef.current);
    }

    // Observe the intro section
    if (introRef.current) {
      observer.observe(introRef.current);
    }

    // Store references to current DOM nodes to avoid closure issues
    const rowElements = rowRefs.current.map(ref => ref.current);

    // Observe each row individually
    rowElements.forEach(element => {
      if (element) {
        observer.observe(element);
      }
    });

    // Clean up the observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []); // Empty dependency array is fine now

  return (
    <div className="our-science" ref={sectionRef}>
      <div className="science-container">
        <h2 className="science-title fade-element" ref={titleRef}>Our Science</h2>
        
        <div className="science-intro fade-element" ref={introRef}>
          <p>
          At Forth Therapeutics, we're pioneering precision therapeutics that target the 
          underlying mechanisms driving fibrosis. Building on world-renowned research from 
          Professor Neil Henderson at the University of Edinburgh, we leverage multi-omic 
          insights from patient-derived tissue samples to develop potent, 
          new therapies for patients. 
          </p>
        </div>
        
        <div className="science-content">
          <div className="science-row fade-element" ref={rowRefs.current[0]}>
            <div className="science-text">
              <h3>Novel Approach to Fibrotic Disease</h3>
              <p>
              We combine cutting-edge single cell and spatial genomics approaches to identify 
              antifibrotic targets with high precision. By focusing on specific markers of 
              pathogenic cells, we're able to develop targeted interventions 
              to halt and potentially reverse the course of fibrotic disease. 
              </p>
            </div>
            
            <div className="science-image">
              <img src="/fibro-tweak.png" alt="Fibrotic disease mechanism" />
            </div>
          </div>
          
          <div className="science-row fade-element" ref={rowRefs.current[1]}>
            <div className="science-text">
              <h3>Unparalleled data access</h3>
              <p>
              Our platform combines high-resolution mapping of the fibrotic niche with 
              one of the largest single-cell human liver disease datasets in 
              the world. Using advanced computational models and machine 
              learning algorithms, we integrate data from multiple single cell 
              and spatial genomics platforms to create a high-definition map of
              fibrotic disease, thereby accelerating precision drug discovery and development. 
              </p>
            </div>
            
            <div className="science-image">
              <img src="/fibro6.png" alt="Data visualization" />
            </div>
          </div>

          <div className="science-row fade-element" ref={rowRefs.current[2]}>
            <div className="science-text">
              <h3>Our Therapies</h3>
              <p>
              Our portfolio includes three assets targeting key fibrosis pathways, 
              underpinned by state-of-the-art omics technology. 
              We believe our technology has broad applicability across fibrotic diseases. 
              We will be announcing further details for our therapeutic development pipeline in Q4 2025. 
              </p>
            </div>
            
            <div className="science-image">
              <img src="/handhold.png" alt="pipeline" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurScience;