// src/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  const scrollToSection = (sectionClass) => {
    const section = document.querySelector(`.${sectionClass}`);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-navigation">
          <div className="footer-links">
            <a href="#our-science" onClick={(e) => { e.preventDefault(); scrollToSection('our-science'); }}>Our Science</a>
            <a href="#leadership-team" onClick={(e) => { e.preventDefault(); scrollToSection('leadership-team'); }}>Leadership</a>
            <a href="#careers" onClick={(e) => { e.preventDefault(); scrollToSection('careers'); }}>Careers</a>
            <a href="#contact-us" onClick={(e) => { e.preventDefault(); scrollToSection('contact-us'); }}>Contact</a>
          </div>
        </div>
        
        <div className="footer-social">
          <a href="https://www.linkedin.com/company/forth-therapeutics" target="_blank" rel="noopener noreferrer" className="linkedin-link">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
              <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
            </svg>
            Follow us on LinkedIn
          </a>
        </div>
        
        <div className="footer-locations">
          <div className="locations-container">
            <p><strong>London:</strong> 1 Pancras Square, C/O Sofinnova Partners, London, N1C 4AG</p>
            <p><strong>Cambridge:</strong> 260 Meditrina, Babraham Research Campus, Cambridge, CB22 3AT</p>
            <p><strong>Edinburgh:</strong> Institute for Repair & Regeneration, 4-5 Little France Dr, Edinburgh, EH16 4UU</p>
          </div>
        </div>
        
        <div className="footer-legal">
          <p>© {currentYear} Forth Therapeutics Ltd • Company Number: 15702694</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;