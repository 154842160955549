// src/Tagline.js
import React from 'react';
import './Tagline.css';

const Tagline = () => {
  return (
    <div className="tagline">
      <h1>Precision therapeutics for fibrotic disease</h1>
      <img src="/forth-flecks.png" alt="Company Logo" className="logo" />
    </div>
  );
};

export default Tagline;
