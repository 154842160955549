// src/LeadershipTeam.js
import React, { useEffect, useRef, useState } from 'react';
import './LeadershipTeam.css';

const LeadershipTeam = () => {
  const sectionRef = useRef(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentSection = sectionRef.current;
    if (currentSection) {
      observer.observe(currentSection);
    }

    // Check if we're on mobile view
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    // Initial check
    checkIfMobile();
    
    // Add event listener for window resize
    window.addEventListener('resize', checkIfMobile);

    return () => {
      if (currentSection) {
        observer.unobserve(currentSection);
      }
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const toggleBio = (index) => {
    if (selectedMember === index) {
      setSelectedMember(null);
    } else {
      setSelectedMember(index);
    }
  };

  // Updated team members array with only 3 profiles
  const teamMembers = [
    {
      name: 'Alex Leech, MBA',
      title: 'Chief Executive Officer',
      bio: 'Alex is CEO of Forth Tx and a Partner in the Sofinnova Biovelocita fund. He is an experienced biopharma company builder and a serial CEO.  He was the founding CEO of Alchemab Tx, which raised an $82m Series A in 2021 and previously the CEO of the clinical stage dermatology company Creabilis Tx until its acquisition by Sienna Biopharmaceuticals Inc. in 2016. Alex’s other company-building roles include serving as CEO of Camphos Tx, Entrepreneur in Residence at Bicycle Tx, and as a Venture Partner with SV Health Investors in London. He is currently Chair of the Board at two start-ups, Ensocell Tx and Sevenless Tx. Alex gained his R&D experience at Pfizer. He holds a BEng in Mechanical Engineering from the University of Sussex and an MBA from Henley Management College, UK.',
      image: '/leechy.png'
    },
    {
      name: 'Neil Henderson, MD PhD FMedSci',
      title: 'Chief Scientific Officer',
      bio: 'Neil Henderson, Chair of Tissue Repair and Regeneration at the University of Edinburgh, is an academic hepatologist with an international profile in defining the cellular and molecular mechanisms driving organ fibrosis. Neil’s group leverages cutting-edge approaches including the rapidly evolving field of single cell and spatial genomics to develop precision therapies for patients with fibrosis.',
      image: '/hendo.png'
    },
    {
      name: 'Ralph Minter, PhD',
      title: 'Chief Technology Officer',
      bio: 'Ralph Minter is an expert in antibody and biologics discovery with over 25 years’ experience in the area. He has developed both platform technologies and clinical stage drugs in multiple disease areas through positions at Cambridge Antibody Technology, MedImmune, AstraZeneca and Alchemab.',
      image: '/ralph.png'
    },
    //     {
    //   name: 'Henry Farmery, PhD',
    //   title: 'Head of Operations',
    //   bio: 'Henry is an experienced company creator and computational biologist. He was previously Director of Informatics at Alchemab Therapeutics. Henry holds a PhD in Computational Biology from the University of Cambridge.',
    //   image: '/farmzy2.png'
    // },
    // {
    //   name: 'Kylie Matchett, PhD',
    //   title: 'Principle Investigator',
    //   bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eget felis eget urna ultrices tincidunt. Vivamus lacinia, nunc eu tincidunt aliquam, nisi magna faucibus lectus, vel sodales magna nulla in.',
    //   image: '/matchetty.png'
    // }
  ];

  return (
    <div className="leadership-team" ref={sectionRef}>
      <h2>Leadership</h2>
      <div className="team-container">
        <div className="team-grid">
          {teamMembers.map((member, index) => (
            <div 
              className="team-member" 
              key={`member-${index}`} 
              style={{transitionDelay: `${index * 0.1}s`}}
            >
              <div className="member-image">
                <img src={member.image} alt={member.name} />
              </div>
              <div className="member-info">
                <h3>
                  {member.name.split(',')[0]}
                  {member.name.includes(',') && <span className="post-nominals">{member.name.split(',')[1]}</span>}
                </h3>
                <h4>{member.title}</h4>
                <button 
                  className={`bio-toggle ${selectedMember === index ? 'expanded' : ''}`}
                  onClick={() => toggleBio(index)}
                >
                  Biography <span className="arrow">{selectedMember === index ? '▲' : '▼'}</span>
                </button>
              </div>
              
              {/* Mobile bio that appears directly under each profile */}
              {isMobile && selectedMember === index && (
                <div className="mobile-bio">
                  <div className="bio-content">
                    <p>{member.bio}</p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        
        {/* Desktop biography section that's shown conditionally */}
        {!isMobile && selectedMember !== null && (
          <div className="full-width-bio">
            <div className="bio-content">
              <h3>{teamMembers[selectedMember].name}</h3>
              <p>{teamMembers[selectedMember].bio}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeadershipTeam;